<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="dashboard-notification">
            <div v-for="item in notificationList" :key="item.id" class="dashboard-notification-inner-text">
              {{ item.notificationName }}
            </div>
          </div>
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>

              <div class="col-lg-9">
                <div
                  class="tab-content myaccount-tab-content"
                  id="account-page-tab-content"
                >

                
                  <div
                    class="tab-pane fade show active"
                    id="account-dashboard"
                    role="tabpanel"
                    aria-labelledby="account-dashboard-tab"
                  >
                    <div
                      class="pro-b-w-wrap noPrint"
                      style="margin-bottom: 25px"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-heart one1"
                                aria-hidden="true"
                              ></i>
                              <h4>
                                <a href="/mywishlist">Wishlisted</a>
                              </h4>
                              <router-link to="/mywishlist">
                                <h3>{{ dashBoard.Wishlisted }}</h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-spinner one2"
                                aria-hidden="true"
                              ></i>
                              <h4>
                                <a href=""
                                  >Process winnings</a
                                >
                              </h4>
                              <a>
                                <h3>
                                  {{ dashBoard.processWinning }}
                                </h3></a >
                            </div>
                          </div>
                        </div> -->
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-trophy one3"
                                aria-hidden="true"
                              ></i>
                              <h4><a href="/MyWinnings">Won auctions</a></h4>
                              <router-link to="/MyWinnings">
                                <h3>{{ dashBoard.Won }}</h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i
                                class="fa fa-credit-card one4"
                                aria-hidden="true"
                              ></i>
                              <h4><a href="/PaymentDues">Payment dues</a></h4>
                              <router-link to="/PaymentDues">
                                <h3>
                                  {{ dashBoard.paymentDues }}
                                </h3></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-new-card-wrap noPrint">
                      <div class="row">
                        <div class="col-md-6">
                          <div
                            class="profile-new-card"
                            style="position: relative"
                          >
                            <div class="mem-details">
                              <img
                                src="../../public/assets/images/account/userProfile.png"
                                class="img-fluid mem-details-img"
                              />
                              <div class="mem-det-wrap-content">
                                <h6>{{ currentuserinfo.custName }}</h6>
                                <span v-if=" packageDetails &&  packageDetails.packName !== ''">Package : {{packageDetails.packName}}</span>
                                <span class="text-center">
                                  <a
                                    @click="showEditModal()"
                                    class="edit-prico"
                                  >
                                    <i class="fas fa-edit"></i
                                  ></a>
                                </span>
                              </div>
                            </div>
                            <div class="mem-c-de">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="mem-c-de-wrap">
                                    <i class="fa fa-phone"></i>
                                    <span>
                                      {{ currentuserinfo.custPhone }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="mem-c-de-wrap">
                                    <i class="fa fa-envelope"></i>
                                    <span class="span-m-class">
                                      {{ currentuserinfo.custEmail }}</span
                                    >
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="">
                                    <div v-if="packageDetails">
                                      <span
                                        >{{ packageDetails.balancePackage }}/{{
                                          packageDetails.packAuctions
                                        }}
                                        Auctions remaining</span
                                      ><span>
                                      
                                        <a  class="upg-now"  @click="gotoPackagePage">Upgrade</a>
                                        
                                        </span
                                      >
                                    </div>
                                    <div v-else class="text-center">
                                      <span>No package available</span
                                      ><span>
                                        
                                        <a  class="bnpkg"  @click="gotoPackagePage">Buy new package</a>
                                        
                                        </span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="mybids.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Bids</h4>
                              <a href="/Mybidz">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Bid amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(mybid, index) in mybids"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ mybid.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      mybid.latestBidAmount | currency
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        mybid.aucMatDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="wonAuctions.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Won</h4>
                              <a href="/MyWinnings">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Bid amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(won, index) in wonAuctions"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ won.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      won.myBidAmount | currency
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        won.actualMaturityDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="paymentDues.length !== 0">
                          <div class="profile-new-card">
                            <div class="profile-new-card-head">
                              <h4>Payment dues</h4>
                              <a href="PaymentDues">View More</a>
                            </div>
                            <div class="profile-new-card-content">
                              <table class="data pro-new-table">
                                <tr>
                                  <th>Auction Name</th>
                                  <th>Amount</th>
                                  <th>End Date</th>
                                </tr>
                                <tr
                                  v-for="(due, index) in paymentDues"
                                  :key="index"
                                >
                                  <td>
                                    <span>{{ due.aucName }}</span>
                                  </td>
                                  <td>
                                    <span>{{
                                      due.tokenAmount | currency
                                    }}</span>
                                  </td>
                                  <td>
                                    <span
                                      >{{
                                        due.actualMaturityDate | formatDate
                                      }}
                                      IST</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- make it modal -->
                    <div class="modal" v-if="isEditModalActive">
                      <div class="modal-content modal-lg" style="padding:0px;">
                        <div
                      class="noPrint"
                      style=""
                    >
                      <div class="l-section" style="padding:45px;">
                        <h5 class="c-section__heading">Details</h5>
                        <b-button
                          style="float: right;    position: absolute;
    top: 10px;
    right: 10px;"
                          variant="outline-primary"
                          @click="closeEditModal()"
                        >
                         Close</b-button
                        >
                        <!-- <button class="clicktoedit" @click="disabled = (disabled + 1) % 2">Click To Edit</button> -->
                        <form class="formzzz">
                          <div v-if="!currentuserinfo"></div>
                          <div class="row" v-else>
                            <div class="col-md-6">
                              <div>
                                <label class="labelzzz">Name</label>
                                <input
                                  v-validate="'required|min:3|max:20'"
                                  type="text"
                                  name="name"
                                  id="custName"
                                  v-model="currentuserinfo.custName"
                                  class="inputzzz"
                                  placeholder="User name"
                                />
                                <span
                                  v-show="errors.has('name')"
                                  class="help is-danger"
                                  >{{ errors.first("name") }}</span
                                >
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div>
                                <label class="labelzzz">Email</label>
                                <input
                                  type="email"
                                  v-validate="'required|email|max:50'"
                                  name="email"
                                  v-model="currentuserinfo.custEmail"
                                  class="inputzzz"
                                  placeholder="Email"
                                />
                                <span
                                  v-show="errors.has('email')"
                                  class="help is-danger"
                                  >{{ errors.first("email") }}</span
                                >
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div>
                                <label class="labelzzz">Phone</label>
                                <VuePhoneNumberInput
                                  v-model="numberMobile"
                                  style="padding-top: 7px"
                                  :default-country-code="countryCode"
                                  :required="true"
                                  color="red"
                                  size="lg"
                                  :noExample="true"
                                  @update="resultsExample = $event"
                                />
                                <div
                                  v-if="submitted && phoneMessageError"
                                  class="alert-danger"
                                >
                                  {{ phoneMessageError }}
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div style="text-align: right">
                                <button
                                  style="
                                    width: 175px;
                                    border-radius: 4px;
                                    font-weight: 500;
                                    margin-top: 43px;
                                    padding: 11px;
                                  "
                                  type="button"
                                  @click="updateuserinfo"
                                  class="buttonzzz"
                                >
                                  UPDATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                      
                      </div>
                    </div>
                   

                    <div
                      class="row noPrint"
                      v-if="packageDetails"
                      style="margin: 0px 3px; display: none"
                    >
                      <h3
                        class="fontWeight500 paddingBottom12"
                        style="padding-left: 0px"
                      >
                        Package
                      </h3>
                      <div class="upgradebidwrap">
                        <div class="upgradebidleft">
                          <h4>{{ packageDetails.packName }}</h4>

                          <a class="bidleftbtn" style="display: inline-block"
                            >{{ packageDetails.balancePackage }}/{{
                              packageDetails.packAuctions
                            }}
                            AUCTIONS</a
                          >
                          Remaining
                        </div>
                        <div class="upgradebidright">
                         

                          <a  class="upgradenowbtn"  @click="gotoPackagePage">Upgrade</a>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="!packageDetails"
                      class="row noPrint"
                      style="margin: 0px 3px; display: none"
                    >
                      <div class="upgradebidwrap">
                        <div class="expiredPackageCls">
                          <div class="">
                            <h3>No Package Available</h3>
                          </div>
                        </div>
                        <div class="upgradebidright">
                          <a class="upgradenowbtn"  @click="gotoPackagePage">
                            Purchase new package
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div
                        id="packageHistory"
                        class="processwinnings"
                        v-if="packageHistory.length"
                      >
                        <section style="" class="autionlog-table">
                          <div class="l-r-flex">
                            <h5>Package Purchase History</h5>
                            <button
                              class="btn noPrint"
                              style=""
                              @click="printWindow()"
                            >
                              <img src="https://cdn-icons-png.flaticon.com/512/1390/1390318.png" style="width:20px;"> Print
                            </button>
                          </div>
                          <!-- <selectioncontainer></selectioncontainer> -->

                          <!-- STARTS HERE -->
                          <div class="table-wrapper">
                            <div class="table-cont">
                              <div class="rowz headerzz">
                                <div class="cellz">Package Name</div>

                                <div class="cellz">Package Amount</div>
                                <div class="cellz">Purchase date</div>
                                <div class="cellz">Package Validity</div>
                              </div>

                              <div
                                class="rowz"
                                v-for="(list, index) in packageHistory"
                                :key="index"
                              >
                                <div class="cellz" data-title="Package Name">
                                  {{ list.packName }}
                                </div>
                                <div class="cellz" data-title="Package Amount">
                                  {{ list.packAmount | currency }}
                                </div>
                                <div class="cellz" data-title="Purchase date">
                                  {{ list.orderDate | formatDate }} IST
                                </div>
                                <div
                                  class="cellz"
                                  data-title="Package Validity"
                                >
                                  {{ list.packValidity }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import CountryCodeList from "../../public/AddOn/CountryCodeList.js";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import userService from "../services/user.service";
import Config from "../config";
// import user from "../models/localstorage";
import moment from "moment";

export default {
  name: "MyAccount",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      currentUserinfo: "",
      currentuserinfo: "",
      message: "",
      disabled: 0,
      packageDetails: "",
      customerList: "",
      countryCode: "",
      numberMobile: null,
      packageHistory: "",
      phoneMessageError: "",
      resultsExample: null,
      submitted: false,
      output: null,
      // dueStyle:""
      dashBoard: [],
      mybids: [],
      wonAuctions: [],
      paymentDues: [],
      isEditModalActive: false,
      notificationList:[],
    };
  },
  created() {},
  updated() {
    if (this.resultsExample != null) {
      if (this.resultsExample.isValid == true && this.phoneMessageError != "") {
        this.phoneMessageError = "";
        // console.log(this.resultsExample.isValid);
      }
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
      this.getuserbyId();
      this.getUserPackage();
      this.getPackageHistory(this.st.customerId);
      this.getDashboardProfileFe(this.st.customerId, "customer");
      this.getNotificationList(this.st.customerId);

      //getCustomerBids
      //getCustomerWinnings
      //getPaymentDue
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {


    gotoPackagePage(){
     // Set the returnUrl value in session storage
      sessionStorage.setItem('returnUrl', '/MyAccount');
      this.$router.push('/package');
    },
    printWindow() {
      window.print();
    },

    showEditModal() {
      this.isEditModalActive = !this.isEditModalActive;
    },

    closeEditModal(){
    this.isEditModalActive =false;
    },
    getuserbyId() {
      let id = this.st.customerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getCustomerDetailById(id)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            //
            let lodsad = this.currentuserinfo.custPhone.split("-");

            this.numberMobile = lodsad[1];
            for (let index = 0; index < CountryCodeList.length; index++) {
              const element = CountryCodeList[index];
              if (element.CountryCallingCode == lodsad[0]) {
                this.countryCode = element.countryCode;
              }
            }
            // console.log(lodsad[1]);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getUserPackage() {
      let id = this.st.customerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService.getUserPackage(id,'customer').then((response) => {
          // console.log(response.data);
          this.packageDetails = response.data.Data[0];
        });
      }
    },
    getPackageHistory(custid) {
      // getCustomerPackages
      let id = custid;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService.getPackageHistory(id,'customer').then((response) => {
          // console.log(response.data);
          this.packageHistory = response.data.Data;
        });
      }
    },
    getDashboardProfileFe(custid, type) {
      // getCustomerPackages
      let id = custid;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService.getDashboardProfileFe(id, type).then((response) => {
          //
          this.dashBoard = response.data.Data.result1[0];

          this.mybids = response.data.Data.result2;
          this.wonAuctions = response.data.Data.result3;
          this.paymentDues = response.data.Data.result4;
        });
      }
    },

    // notificationList

    getNotificationList(custid) {
      // getCustomerPackages
      let id = custid;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService.getNotificationList(id, ).then((response) => {
   
          this.notificationList = response.data.Data;
          //this.mybids = response.data.Data.result2;
          
        });
      }
    },

    //////
    updateuserinfo() {
      this.submitted = true;
      this.currentuserinfo.custPhone =
        "+" +
        this.resultsExample.countryCallingCode +
        "-" +
        this.resultsExample.nationalNumber;

      this.$validator.validateAll().then((result) => {
        if (result) {
          let id = this.st.customerId;
          if (id == "undefind") {
            console.log("undefind");
          } else {
            if (this.resultsExample.isValid == true) {
              this.phoneMessageError = "";
              userService
                .update(id, this.currentuserinfo)
                .then((response) => {
                  if (response.data.Message == "Success") {
                    this.makeToast("success", "Profile updated successfully");

                    setTimeout(() => {
                      this.profileinfo();
                    }, 2000);
                  }

                  // this.getuserbyId();
                })
                .catch((err) => {
                  let resMessage =
                    (err.response && err.response.data.Message) ||
                    err.message ||
                    err.toString();
                  this.makeToast("danger", resMessage);
                });
            } else {
              this.phoneMessageError = "Please check the phone number";
              // this.makeToast("warning", this.phoneMessageError);
            }
          }
          return;
        }
        // alert("correct them errors");
      });
    },
    pagerefresh() {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    },
    profileinfo() {
      location.reload();
      /// this.makeToast("success", "Profile updated successfully");
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: Config.toastDelay,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
}
.clicktoedit {
  transition-duration: 0.4s;
}

.clicktoedit:hover {
  background-color: #4caf50; /* Green */
  color: white;
}
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

/* @media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
} */

@media Print {
  @page {
    size: auto;
    margin: 0mm;
  }
  .uren-footer_area {
    display: none !important;
    visibility: hidden;
  }
  .noPrint,
  .account_border-right {
    display: none !important;
    visibility: hidden;
    @page {
      size: auto;
      margin: 0mm;
    }
  }
}

.expiredPackageCls {
  padding: 25px 45px;
  flex: 62%;
  text-align: left;
}
.expiredPackageCls h3 {
  color: rgb(233, 43, 43);
}
.table-cont {
  margin: 0px !important;
}
</style>
